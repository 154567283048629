import { isFunction, merge } from 'lodash-es';
import { mapActions } from 'vuex';

import { PAGEVIEW } from '~coreModules/core/js/global-event-constants';

import { GLOBAL_EVENT } from '~coreModules/core/js/store';

export default {
    mounted() {
        if (!this.disablePageViewInMounted) {
            this.firePageview();
        }
    },
    methods: {
        ...mapActions({
            trackGlobalEvent: GLOBAL_EVENT,
        }),
        getParents() {
            const parents = this.$route.matched.map(parent => ({ ...parent }));
            parents.pop();
            return parents;
        },
        firePageview() {
            if (process.env.VUE_ENV === 'client') {
                let data = { currentPage: this.$route };

                if (isFunction(this.$options.getAnalyticsData)) {
                    const pageData = this.$options.getAnalyticsData.call(this);
                    data = merge(data, pageData);
                }

                data.parents = this.getParents();

                this.trackGlobalEvent({
                    type: PAGEVIEW,
                    data,
                });
            }
        },
    },
};
