<template>
    <Component :is="layout" :flushWithHeader="true" :topMargin="headerMargin">
        <slot :rows="rows" :contentTitle="contentTitle"></slot>
    </Component>
</template>

<script>
import { cloneDeep, get } from 'lodash-es';
import { mapGetters, mapState } from 'vuex';

import { DISCOVER_MODULE_NAME } from '~coreModules/discover/js/discover-store';

import analyticsMixin from '~coreModules/analytics/js/analytics';

export default {
    name: 'BaseDiscoverPageContainer',
    mixins: [analyticsMixin],
    props: {
        layout: {
            type: [Function, Object],
            required: true,
        },
    },
    computed: {
        ...mapGetters(DISCOVER_MODULE_NAME, ['headerMargin']),
        ...mapState(DISCOVER_MODULE_NAME, {
            contentTitle: state => get(state.contentPage, 'title', ''),
            rows: state => cloneDeep(get(state.contentPage, 'rows', [])),
            navItem: state => state.navItem,
        }),
    },
};
</script>

<style lang="scss">

</style>
