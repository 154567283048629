import { mapState } from 'vuex';

import metaMixin from '~coreModules/core/js/seo/meta-mixin';

import { DISCOVER_MODULE_NAME } from '~coreModules/discover/js/discover-store';

export default {
    mixins: [metaMixin],
    computed: {
        ...mapState(DISCOVER_MODULE_NAME, {
            navItem: state => state.navItem,
        }),
    },
};
