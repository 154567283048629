import { isEmpty } from 'lodash-es';
import { getRobots } from '~coreModules/core/js/seo/meta-utils';

export default {
    getMetaTags() {
        if (this.noMeta) {
            return [];
        }

        if (isEmpty(this.navItem)) {
            return [];
        }

        const { seo } = this.navItem;
        if (!seo) {
            return [];
        }

        const { title, noIndex, noFollow, metaDescription } = seo;

        return [{
            name: 'robots',
            content: getRobots(noIndex, noFollow),
        }, {
            name: 'description',
            content: metaDescription,
        }, {
            property: 'og:title',
            content: title,
        }, {
            property: 'og:description',
            content: metaDescription,
        }];
    },

    getPageTitle() {
        if (isEmpty(this.navItem)) {
            return null;
        }

        const { seo } = this.navItem;

        if (seo && seo.title) {
            return this.$t(this.SITE_META.defaultTitle, [seo.title]);
        }

        if (this.navItem.displayName) {
            return this.$t(this.SITE_META.defaultTitle, [this.navItem.displayName]);
        }

        return null;
    },
};
