<template>
    <div>
        <main
            :class="{
                'o-container': true,
                'o-container--no-bottom-padding': noBottomPadding,
                'o-container--no-top-padding': flushWithHeader,
                'o-container--mobile-webview': isMobileWebview,
            }"
            :style="topMarginOverride"
        >
            <AsyncContentFadeIn :hasMetMinimLoadTime="isMounted">
                <AsyncError500 v-if="is400" :contentfulContentBlock="contentfulContentBlock" />
                <AsyncError404 v-else-if="is404" :contentfulContentBlock="contentfulContentBlock" />
                <AsyncError500 v-else-if="is500" :contentfulContentBlock="contentfulContentBlock" />
                <slot v-else></slot>
            </AsyncContentFadeIn>
        </main>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { get } from 'lodash-es';
import { mapGetters } from 'vuex';

import AsyncContentFadeIn from '~coreModules/core/components/ui/AsyncContentFadeIn.vue';

export default {
    name: 'CoreLayout',
    components: {
        // Async import so it's only pulled client side if a 400, 404 or 500 actually occurs
        AsyncError404: defineAsyncComponent(() => import('~coreModules/core/components/errors/Error404.vue')),
        AsyncError500: defineAsyncComponent(() => import('~coreModules/core/components/errors/Error500.vue')),
        AsyncContentFadeIn,
    },
    props: {
        contentfulContentBlock: {
            type: [Function, Object],
            required: true,
        },
        flushWithHeader: {
            type: Boolean,
            default: false,
        },
        topMargin: {
            type: Number,
            default: null,
        },
        noBottomPadding: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isMounted: false,
        };
    },
    computed: {
        ...mapGetters(['isMobileWebview']),
        topMarginOverride() {
            return this.topMargin ? { marginTop: `${this.topMargin}px` } : {};
        },
        is400() {
            return get(this.$store, 'state.routingError.code') === 400;
        },
        is404() {
            return get(this.$store, 'state.routingError.code') === 404;
        },
        is500() {
            return get(this.$store, 'state.routingError.code') === 500;
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.isMounted = true;
        });
    },
};
</script>

<style lang="scss">

</style>
