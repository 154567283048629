<template>
    <BaseDiscoverPageContainer :layout="Layout">
        <template #default="{ rows, contentTitle }">
            <ContentfulContentBlock
                :contentRows="rows"
                :contentTitle="contentTitle"
            />
        </template>
    </BaseDiscoverPageContainer>
</template>

<script>
import { markRaw } from 'vue';
import { DISCOVER_MODULE_NAME, FETCH_DISCOVER_CONTENT } from '~coreModules/discover/js/discover-store';

import discoverMetaMixin from '~coreModules/discover/js/discover-meta';
import metaTagsMixin from '~modules/core/js/seo/meta-tags';

import BaseDiscoverPageContainer from '~coreModules/discover/components/BaseDiscoverPageContainer.vue';
import ContentfulContentBlock from '~modules/contentful/components/ContentfulContentBlock.vue';
import Layout from '~modules/core/components/layouts/Layout.vue';

export default {
    name: 'DiscoverPageContainer',
    components: {
        BaseDiscoverPageContainer,
        ContentfulContentBlock,
    },
    mixins: [discoverMetaMixin, metaTagsMixin],
    data() {
        return {
            Layout: markRaw(Layout),
        };
    },
    fetchData({ store, route }) {
        const { slug } = route.params;
        const { isLegalPage = false } = route.meta;

        return store.dispatch(`${DISCOVER_MODULE_NAME}/${FETCH_DISCOVER_CONTENT}`,
            { slug, isLegalPage });
    },
};
</script>

<style lang="scss">

</style>
