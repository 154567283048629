<template>
    <CoreLayout v-bind="$props" :contentfulContentBlock="ContentfulContentBlock">
        <slot></slot>
    </CoreLayout>
</template>

<script>
import { markRaw, defineAsyncComponent } from 'vue';
import CoreLayout from '~coreModules/core/components/layouts/CoreLayout.vue';

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Layout',
    components: {
        CoreLayout,
    },
    data() {
        return {
            ContentfulContentBlock: markRaw(
                defineAsyncComponent(
                    () => import(
                        /* webpackChunkName: "contentful-content-block" */
                        '~modules/contentful/components/ContentfulContentBlock.vue'),
                ),
            ),
        };
    },
};
</script>
